import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <div
      className="error"
      style={{
        position: `fixed`,
        width: `100vw`,
        height: `100vh`
      }}
    >
      <SEO title="404: Not found" />
      <div
        className="error-wrapper"
        style={{
          position: `fixed`,
          left: `50vw`,
          height: `50vw`,
          transform: `translate(-50%,-50%)`,
          color: `whitesmoke`
        }}
      >
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist...</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
